
import { defineComponent } from 'vue'
import ReportingCallsTableMonth from '@/components/pages/reporting/messagesCalls/calls/ReportingCallsTableMonth.vue'
import ReportingMessagesCallsTableFilterYearMonth from '@/components/pages/reporting/messagesCalls/ReportingMessagesCallsTableFilterYearMonth.vue'
import ReportingCallsStats from '@/components/pages/reporting/messagesCalls/calls/ReportingCallsStats.vue'

export default defineComponent({
  components: {
    ReportingCallsStats,
    ReportingMessagesCallsTableFilterYearMonth,
    ReportingCallsTableMonth,
  },
})
