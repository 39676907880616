
import { defineComponent, ref, computed } from 'vue'
import TmTable from '@/components/shared/table/TmTable.vue'
import type { TableHeaders } from '@/definitions/shared/types'
import { useRoute } from 'vue-router'
import { formatDate } from '@/services/dateTimeService'

export default defineComponent({
  components: {
    TmTable,
  },
  setup() {
    const route = useRoute()
    const year = computed(() => route.params.year)
    const month = computed(() => route.params.month)

    const tableHeaders = ref<TableHeaders[]>([
      { text: 'Day', value: 'data-slot' },
      { text: 'Total calls', value: 'totalCalls' },
      { text: 'Total talk time', value: 'totalTalkTime' },
      { text: 'Costs', value: 'costs' },
    ])

    const tableItems = computed(() => [
      { data: `${year.value}-${month.value}-01`, totalCalls: '1,445', totalTalkTime: '12:33:18', costs: '$ 993.00' },
      { data: `${year.value}-${month.value}-03`, totalCalls: '2,543', totalTalkTime: '12:33:18', costs: '$ 138.00' },
      { data: `${year.value}-${month.value}-05`, totalCalls: '445', totalTalkTime: '12:33:18', costs: '$ 441.00' },
      { data: `${year.value}-${month.value}-07`, totalCalls: '123', totalTalkTime: '12:33:18', costs: '$ 451.00' },
      { data: `${year.value}-${month.value}-09`, totalCalls: '2,491', totalTalkTime: '12:33:18', costs: '$ 483.00' },
      { data: `${year.value}-${month.value}-11`, totalCalls: '301', totalTalkTime: '12:33:18', costs: '$ 180.00' },
      { data: `${year.value}-${month.value}-13`, totalCalls: '840', totalTalkTime: '12:33:18', costs: '$ 1,670.00' },
      { data: `${year.value}-${month.value}-15`, totalCalls: '1,993', totalTalkTime: '12:33:18', costs: '$ 448.00' },
      { data: `${year.value}-${month.value}-17`, totalCalls: '2,973', totalTalkTime: '12:33:18', costs: '$ 397.00' },
      { data: `${year.value}-${month.value}-19`, totalCalls: '75', totalTalkTime: '12:33:18', costs: '$ 217.00' },
      { data: `${year.value}-${month.value}-21`, totalCalls: '629', totalTalkTime: '12:33:18', costs: '$ 451.00' },
      { data: `${year.value}-${month.value}-23`, totalCalls: '1,114', totalTalkTime: '12:33:18', costs: '$ 448.00' },
    ])

    return {
      formatDate,
      tableHeaders,
      tableItems,
    }
  },
})
